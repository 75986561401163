import {mapActions, mapGetters, mapMutations} from "vuex";
import AOS from "aos";
//sections
export default {
  name: "article",
  components: {},
  watch: {
    '$route'(newVal) {
      if (newVal) {
        this.fetchPageContent(this.$route.params.slug)
      }
    }
  },
  data() {
    return {
      saf: false,
    }
  },

  created() {
    this.fetchPageContent(this.$route.params.slug)
    function iOS() {
      return [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
    this.saf = iOS()
  },

  computed: {
    ...mapGetters({
      blogContent: 'pages/blog'
    }),
  },
  mounted() {
    AOS.init({
      disable: function () {
        const maxWidth = 700;
        return window.innerWidth <= maxWidth;
      }
    });
  },
  methods: {
    ...mapActions({
      fetchPageContent: 'pages/GET_BLOG'
    }),
  }
}